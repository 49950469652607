<template>
  <div>
    <el-header height="300">
      <div v-if="data.TypeLb !== `RbLittle`">
        <el-button type="primary" v-if="data.IsActive === true" @click="changeActive(false)">
          Active - Click to Disable
        </el-button>
        <el-button type="danger" v-if="data.IsActive === false" @click="changeActive(true)">
          Disable - Click to Active
        </el-button>
      </div>
    </el-header>

    <el-form ref="form" label-width="300px">
      <MyFormItem label="Status Leaderboard" tooltip="Current Status">
        <el-input class="form-input" v-model="data.StatusLb" size="normal" disabled/>
      </MyFormItem>

      <MyFormItem label="Last Modifier" tooltip="Last Modifier">
        <el-input class="form-input" v-model="data.LastModifier" size="normal" disabled/>
      </MyFormItem>

      <MyFormItem label="Last Modify Date" tooltip="Last Modify Date">
        <el-date-picker
            v-model="lastModify"
            type="datetime"
            value-format="timestamp"
            disabled
        >
        </el-date-picker>
      </MyFormItem>

      <MyFormItem label="Last Message" tooltip="Last Apply message callback">
        <el-input class="form-input" v-model="updateMsg.msg" size="normal" readonly/>
      </MyFormItem>

      <MyFormItem label="EventId" tooltip="">
        <el-input class="form-input" v-model="data.EventId" size="normal" readonly/>
      </MyFormItem>

      <MyFormItem label="Event Name" tooltip="Tên của event">
        <el-input class="form-input" v-model="data.EventName" placeholder="Example: EventName LBQ 123" size="normal" clearable/>
      </MyFormItem>

      <MyFormItem label="Coming Soon" tooltip="Coming Soon Time">
        <el-date-picker
            v-model="comingSoonTime"
            type="datetime"
            value-format="timestamp"
            placeholder="Select date and time"
        >
        </el-date-picker>
      </MyFormItem>
      <MyFormItem label="Start Time" tooltip="Start Time">
        <el-date-picker
            v-model="startTime"
            type="datetime"
            value-format="timestamp"
            placeholder="Select date and time"
        >
        </el-date-picker>
      </MyFormItem>
      <MyFormItem label="End Time" tooltip="End Time">
        <el-date-picker
            v-model="endTime"
            type="datetime"
            value-format="timestamp"
            placeholder="Select date and time"
        >
        </el-date-picker>
      </MyFormItem>

      <MyFormItem label="Limit Time (second)" tooltip="Thời gian được xem là gần kết thúc khi CurrentTime >= (EndTime - LimitTime)">
        <el-input-number v-model="data.Time.Limit" :step="1" size="medium" :min="0"/>
      </MyFormItem>

      <MyFormItem label="Affect From Version" tooltip="event xuất hiện từ phiên bản nào">
        <el-input class="form-input" v-model="data.AffectFromVersion" placeholder="Example: 1.65.0" size="normal" clearable/>
      </MyFormItem>

      <MyFormItem label="Affect To Version" tooltip="event xuất hiện đến phiên bản nào">
        <el-input class="form-input" v-model="data.AffectToVersion" placeholder="Example: 1.65.0" size="normal" clearable/>
      </MyFormItem>

      <MyFormItem label="Current Index Config" tooltip="Index config sử dụng cho đợt event sau">
        <el-input-number v-model="data.ConfigIndex" size="normal" label="label" :step="1" :controls="true" :min="0"/>
      </MyFormItem>

      <el-collapse class="el-collapse-item" accordion>
        <el-collapse-item title="Avatar" v-if="data.TypeLb !== `RbLittle`">
          <template #title>
            <h2>Avatar</h2>
          </template>
          <!-- Avatar Replace -->
          <MyFormItem label="Avatar Replace" tooltip="Đẩy file Avatar Replace lên">
            <el-row :gutter="20" type="flex" justify="start">
              <el-col :offset="0">
                <el-upload
                    drag
                    action=""
                    :file-list="avatarReplaceFileList"
                    :auto-upload="false"
                    ref=""
                    :multiple="false"
                    :on-change="handleChangeAvatarReplaceFileList"
                    :on-remove="handleChangeAvatarReplaceFileList"
                    class="file-list-item">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                </el-upload>
              </el-col>
              <el-col  :offset="0">
                <el-tooltip content="Tải file .mlb đang show bên dưới" placement="top-start" effect="dark">
                  <el-button size="default" @click="handleDownloadCurrentAvatarReplace"><i class="el-icon-download"></i> Download current config</el-button>
                </el-tooltip>
              </el-col>
            </el-row>
          </MyFormItem>

          <el-collapse class="space-to-bottom" :accordion="false" v-if="data.AvatarReplace.length > 0">
            <el-collapse-item title="View Imported Data">
              <el-table :data="data.AvatarReplace" border>
                <el-table-column
                    type="index"
                    label="Index"
                    width="200"
                />

                <el-table-column
                    label="AvatarId"
                >
                  <template #default="scope">
                    {{ scope.row }}
                  </template>
                </el-table-column>
              </el-table>
            </el-collapse-item>
          </el-collapse>

          <MyFormItem label="Avatar Replace gold" tooltip="">
            <el-input-number v-model="data.AvatarReplaceGold" size="normal" label="label" :step="1" :controls="false" :min="0"/>
          </MyFormItem>

          <MyFormItem label="AvatarFrame Replace" tooltip="Đẩy file AvatarFrame Replace lên">
            <el-row :gutter="20" type="flex" justify="start">
              <el-col :offset="0">
                <el-upload
                    drag
                    action=""
                    :file-list="avatarFrameReplaceFileList"
                    :auto-upload="false"
                    ref=""
                    :multiple="false"
                    :on-change="handleChangeAvatarFrameReplaceFileList"
                    :on-remove="handleChangeAvatarFrameReplaceFileList"
                    class="file-list-item">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                </el-upload>
              </el-col>
              <el-col  :offset="0">
                <el-tooltip content="Tải file .mlb đang show bên dưới" placement="top-start" effect="dark">
                  <el-button size="default" @click="handleDownloadCurrentAvatarFrameReplace"><i class="el-icon-download"></i> Download current config</el-button>
                </el-tooltip>
              </el-col>
            </el-row>
          </MyFormItem>

          <el-collapse class="space-to-bottom" :accordion="false" v-if="avatarFrameTableData.length > 0">
            <el-collapse-item title="View Imported Data">
              <el-table :data="avatarFrameTableData" border>
                <el-table-column
                    prop="Index"
                    label="Index"
                    width="200"
                />

                <el-table-column
                    label="Slot_0"
                    prop="Slot_0"
                />

                <el-table-column
                    label="Slot_1"
                    prop="Slot_1"
                />

                <el-table-column
                    label="Slot_2"
                    prop="Slot_2"
                />

                <el-table-column
                    label="Slot_3"
                    prop="Slot_3"
                    v-if="this.data.AvatarFrameReplace.length > 3"
                />

                <el-table-column
                    label="Slot_4"
                    prop="Slot_4"
                    v-if="this.data.AvatarFrameReplace.length > 4"
                />

                <el-table-column
                    label="Slot_5"
                    prop="Slot_5"
                    v-if="this.data.AvatarFrameReplace.length > 5"
                />

                <el-table-column
                    label="Slot_6"
                    prop="Slot_6"
                    v-if="this.data.AvatarFrameReplace.length > 6"
                />

                <el-table-column
                    label="Slot_7"
                    prop="Slot_7"
                    v-if="this.data.AvatarFrameReplace.length > 7"
                />

                <el-table-column
                    label="Slot_8"
                    prop="Slot_8"
                    v-if="this.data.AvatarFrameReplace.length > 8"
                />

                <el-table-column
                    label="Slot_9"
                    prop="Slot_9"
                    v-if="this.data.AvatarFrameReplace.length > 9"
                />
              </el-table>
            </el-collapse-item>
          </el-collapse>
        </el-collapse-item>

        <el-collapse-item title="Personal" v-if="data.TypeLb !== `RbLittle`">
          <template #title>
            <h2>Personal</h2>
          </template>
          <!-- Reward List -->
          <el-collapse class="space-to-bottom" :accordion="false" v-if="data.RewardData.length > 0">
            <el-collapse-item title="View Reward Data">
              <el-tabs
                  type="border-card"
                  :before-leave="onSwitchTabRewardData"
                  >
                <el-tab-pane
                    v-for="(item, index) in data.RewardData"
                    :key="index"
                    :label="index.toString()"
                    :name="index.toString()"
                >
                  <MyFormItem label="Reward File" tooltip="Đẩy file Reward lên">
                    <el-row :gutter="20" type="flex" justify="start">
                      <el-col :offset="0">
                        <el-upload
                            drag
                            action=""
                            :file-list="rewardDataFileList"
                            :auto-upload="false"
                            ref=""
                            :multiple="true"
                            :on-change="handleChangeRewardDataFileList"
                            :on-remove="handleChangeRewardDataFileList"
                            class="file-list-item">
                          <i class="el-icon-upload"></i>
                          <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                        </el-upload>
                      </el-col>
                      <el-col  :offset="0">
                        <el-tooltip content="Tải file .mlb đang show bên dưới" placement="top-start" effect="dark">
                          <el-button size="default" @click="handleDownloadRewardFile(index)"><i class="el-icon-download"></i> Download current config</el-button>
                        </el-tooltip>
                      </el-col>
                    </el-row>
                  </MyFormItem>

                  <el-tabs
                    type="card"
                    v-model="rewardData.selectedGroupName[index]"
                  >
                    <el-tab-pane
                        v-for="groupData in item"
                        :key="groupData.Name"
                        :label="groupData.Name"
                        :name="groupData.Name"
                    >
                      <el-table :data="groupData.Gift" border v-if="groupData.Gift.length > 0">
                        <el-table-column
                            prop="id"
                            label="Index"
                            width="100"/>
                        <el-table-column
                            prop="title"
                            label="Title"/>
                        <el-table-column
                            prop="rankMin"
                            label="RankMin"/>
                        <el-table-column
                            prop="rankMax"
                            label="RankMax"/>
                        <el-table-column
                            prop="requireScore"
                            label="RequireScore"/>
                        <el-table-column
                            prop="rewards"
                            label="Rewards"
                            width="500">
                          <template #default="propsRewards">
                            <el-table :data="propsRewards.row.rewards" border>
                              <el-table-column
                                  prop="code"
                                  label="Code">
                              </el-table-column>
                              <el-table-column
                                  prop="value"
                                  label="Value"
                                  width="100">
                              </el-table-column>
                            </el-table>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-tab-pane>
                  </el-tabs>
                </el-tab-pane>
              </el-tabs>
            </el-collapse-item>
          </el-collapse>

          <!-- Group Config -->
          <el-collapse class="space-to-bottom" :accordion="false" v-if="data.GroupConfig.length > 0">
            <el-collapse-item title="View GroupConfig Data">
              <MyFormItem label="GroupConfig File" tooltip="Đẩy file GroupConfig lên">
                <el-row :gutter="20" type="flex" justify="start">
                  <el-col :offset="0">
                    <el-upload
                        drag
                        action=""
                        :file-list="groupConfigFileList"
                        :auto-upload="false"
                        ref=""
                        :on-change="handleChangeGroupConfigFileList"
                        :on-remove="handleChangeGroupConfigFileList"
                        class="file-list-item">
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                    </el-upload>
                  </el-col>
                  <el-col  :offset="0">
                    <el-tooltip content="Tải file .mlb đang show bên dưới" placement="top-start" effect="dark">
                      <el-button size="default" @click="handleDownloadCurrentGroupConfigFile"><i class="el-icon-download"></i> Download current config</el-button>
                    </el-tooltip>
                  </el-col>
                </el-row>
              </MyFormItem>
              <el-table :data="data.GroupConfig" border>
                <el-table-column
                    prop="TypeUser"
                    label="Type User (Cheater, Tester, Normal)">
                </el-table-column>
                <el-table-column
                    prop="Country"
                    label="Country (All, Tier1, Tier3, Other)">
                </el-table-column>
                <el-table-column
                    prop="Region.Min"
                    label="Region Min">
                </el-table-column>
                <el-table-column
                    prop="Region.Max"
                    label="Region Max">
                </el-table-column>
                <el-table-column
                    prop="TotalLeaderBoard"
                    label="Total LeaderBoard">
                </el-table-column>
                <el-table-column
                    prop="MaxUserInLeaderBoard"
                    label="Max User In LeaderBoard">
                </el-table-column>
                <el-table-column
                    prop="MaxPayUser"
                    label="Max PayUser">
                </el-table-column>
                <el-table-column
                    prop="MaxFreeUser1"
                    label="Max FreeUser1">
                </el-table-column>
                <el-table-column
                    prop="RewardName"
                    label="Reward Name">
                </el-table-column>
              </el-table>
            </el-collapse-item>
          </el-collapse>
        </el-collapse-item>

        <el-collapse-item title="Clan" v-if="data.TypeLb !== `RbLittle`">
          <template #title>
            <h2>Clan</h2>
          </template>
          <el-collapse class="space-to-bottom" :accordion="false" v-if="data.ClanRewardData.length > 0">
            <el-collapse-item title="View Reward Data">
              <el-tabs
                  type="border-card"
                  :before-leave="onSwitchTabClanRewardData"
              >
                <el-tab-pane
                    v-for="(item, index) in data.ClanRewardData"
                    :key="index"
                    :label="index.toString()"
                    :name="index.toString()"
                >
                  <MyFormItem label="Reward File" tooltip="Đẩy file Reward lên">
                    <el-row :gutter="20" type="flex" justify="start">
                      <el-col :offset="0">
                        <el-upload
                            drag
                            action=""
                            :file-list="clanRewardDataFileList"
                            :auto-upload="false"
                            ref=""
                            :multiple="true"
                            :on-change="handleChangeClanRewardFileList"
                            :on-remove="handleChangeClanRewardFileList"
                            class="file-list-item">
                          <i class="el-icon-upload"></i>
                          <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                        </el-upload>
                      </el-col>
                      <el-col  :offset="0">
                        <el-tooltip content="Tải file .mlb đang show bên dưới" placement="top-start" effect="dark">
                          <el-button size="default" @click="handleDownloadClanRewardFile(index)"><i class="el-icon-download"></i> Download current config</el-button>
                        </el-tooltip>
                      </el-col>
                    </el-row>
                  </MyFormItem>

                  <el-tabs
                      type="card"
                      v-model="clanRewardData.selectedGroupName[index]"
                  >
                    <el-tab-pane
                        v-for="groupData in item"
                        :key="groupData.Name"
                        :label="groupData.Name"
                        :name="groupData.Name"
                    >
                      <el-table :data="groupData.Gift" border v-if="groupData.Gift.length > 0">
                        <el-table-column
                            prop="id"
                            label="Index"
                            width="100"/>
                        <el-table-column
                            prop="title"
                            label="Title"/>
                        <el-table-column
                            prop="rankMin"
                            label="RankMin"/>
                        <el-table-column
                            prop="rankMax"
                            label="RankMax"/>
                        <el-table-column
                            prop="requireScore"
                            label="RequireScore"/>
                        <el-table-column
                            prop="rewards"
                            label="Rewards"
                            width="500"/>
                      </el-table>
                    </el-tab-pane>
                  </el-tabs>
                </el-tab-pane>
              </el-tabs>
            </el-collapse-item>
          </el-collapse>

          <!-- Clan Group Config -->
          <el-collapse class="space-to-bottom" :accordion="false" v-if="data.ClanGroupConfig !== undefined">
            <el-collapse-item title="View GroupConfig Data">
              <MyFormItem label="ClanGroupConfig File" tooltip="Đẩy file ClanGroupConfig lên">
                <el-row :gutter="20" type="flex" justify="start">
                  <el-col :offset="0">
                    <el-upload
                        drag
                        action=""
                        :file-list="clanGroupConfigFileList"
                        :auto-upload="false"
                        ref=""
                        :on-change="handleChangeClanGroupConfigFileList"
                        :on-remove="handleChangeClanGroupConfigFileList"
                        class="file-list-item">
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                    </el-upload>
                  </el-col>
                  <el-col  :offset="0">
                    <el-tooltip content="Tải file .mlb đang show bên dưới" placement="top-start" effect="dark">
                      <el-button size="default" @click="handleDownloadCurrentClanGroupConfigFile"><i class="el-icon-download"></i> Download current config</el-button>
                    </el-tooltip>
                  </el-col>
                </el-row>
              </MyFormItem>
              <el-collapse class="space-to-bottom" :accordion="false" v-if="data.ClanGroupConfig.length > 0">
                <el-collapse-item title="View Imported Data">
                  <el-table :data="data.ClanGroupConfig" border>
                    <el-table-column
                        prop="TypeClan"
                        label="Type Clan (Cheater, Normal)">
                    </el-table-column>
                    <el-table-column
                        prop="Country"
                        label="Country (All, Usa, Tier1, Tier3, Other)">
                    </el-table-column>
                    <el-table-column
                        prop="TotalLeaderBoard"
                        label="Total LeaderBoard">
                    </el-table-column>
                    <el-table-column
                        prop="MaxClanInLeaderBoard"
                        label="Max Clan In LeaderBoard">
                    </el-table-column>
                    <el-table-column
                        prop="RewardName"
                        label="Reward Name">
                    </el-table-column>
                  </el-table>
                </el-collapse-item>
              </el-collapse>
            </el-collapse-item>
          </el-collapse>
        </el-collapse-item >

        <el-collapse-item title="RbGrandSetting" v-if="data.TypeLb === `RbGrand`">
          <template #title>
            <h2>Raidboss Setting</h2>
          </template>

          <!-- Boss Settings -->
          <h2>Boss Settings</h2>
          <el-form label-width="300px">
            <MyFormItem label="Count" tooltip="Số lượng boss trong 1 event">
              <el-input-number v-model="data.BossConfig.Count" size="normal" label="Boss Count" :min="1" :max="3"
                               :step="1" :controls="true" controls-position="both" disabled>
              </el-input-number>
            </MyFormItem>
            <MyFormItem label="Active Time" tooltip="Khoảng thời gian giữa 2 con boss - session 1 con boss (giây)">
              <TimeInput :value="data.BossConfig.ActiveTime" :on-change="handleOnBossActiveTimeChange"/>
            </MyFormItem>

            <MyFormItem label="Living Time" tooltip="Thời gian con boss hoạt động trong session trên (giây)">
              <TimeInput :value="data.BossConfig.LivingTime" :on-change="handleOnBossLivingTimeChange"/>
            </MyFormItem>

            <MyFormItem label="Waiting Time" tooltip="Thời gian chờ khi con boss session trước hết hoạt động đến khi bắt đầu session tiếp theo (giây)">
              <el-input class="form-input" v-model="bossWaitingTime" size="normal" disabled/>
              <SpanTime :numberOfSeconds="bossWaitingTime"/>
            </MyFormItem>
          </el-form>
        </el-collapse-item>

        <el-collapse-item title="VsSetting" v-if="data.TypeLb === `Versus`">
          <template #title>
            <h2>Versus Setting</h2>
          </template>

          <MyFormItem label="Result Log count" tooltip="Result Log count">
            <el-input-number v-model="data.VersusConfig.ResultLogCount" size="normal" label="label" :step="1" :controls="true" :min="1"/>
          </MyFormItem>

          <MyFormItem label="Not same user in battle count" tooltip="Not same user in battle count">
            <el-input-number v-model="data.VersusConfig.NotSameUserInBattleCount" size="normal" label="label" :step="1" :controls="true" :min="0"/>
          </MyFormItem>

          <MyFormItem label="Point range" tooltip="Point range">
            <el-input-number v-model="data.VersusConfig.PointRange" size="normal" label="label" :step="1" :controls="true" :min="0"/>
          </MyFormItem>

          <MyFormItem label="Power delta" tooltip="Power delta">
            <el-input-number v-model="data.VersusConfig.PowerDelta" size="normal" label="label" :step="1" :controls="true" :min="0"/>
          </MyFormItem>

          <MyFormItem label="Battle count" tooltip="Battle Need To Find Real User">
            <el-input-number v-model="data.VersusConfig.BattleNeedToFindUser" size="normal" label="label" :step="1" :controls="true" :min="0"/>
          </MyFormItem>

          <MyFormItem label="Battle Id View" tooltip="Battle ID, download icon appear">
            <el-input class="form-input" v-model="data.VersusConfig.BattleIdView" size="normal" clearable/>
          </MyFormItem>

          <MyFormItem label="Battle Id Play" tooltip="Battle ID, can play event">
            <el-input class="form-input" v-model="data.VersusConfig.BattleIdPlay" size="normal" clearable/>
          </MyFormItem>

          <MyFormItem label="Affect Duration" tooltip="Affect Duration">
            <el-input-number v-model="data.VersusConfig.GroupAffectSetting.GroupAffectDuration" :step="1" size="medium" :min="1"/>
          </MyFormItem>

          <MyFormItem label="Current Affect" tooltip="Current Affect">
            <el-input class="form-input" v-model="showCurrentAffect" size="normal" disabled/>
          </MyFormItem>

          <MyFormItem label="Next time random affect" tooltip="Next time random affect">
            <el-date-picker
                v-model="nextTimeAffectRandom"
                type="datetime"
                value-format="timestamp"
                disabled
            >
            </el-date-picker>
          </MyFormItem>

          <MyFormItem label="PointReward config File" tooltip="Đẩy file PointReward config lên">
            <el-row :gutter="20" type="flex" justify="start">
              <el-col :offset="0">
                <el-upload
                    drag
                    action=""
                    :file-list="pointRewardFileList"
                    :auto-upload="false"
                    ref=""
                    :on-change="handleChangeVsPointRewardFileList"
                    :on-remove="handleChangeVsPointRewardFileList"
                    class="file-list-item">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                </el-upload>
              </el-col>
              <el-col  :offset="0">
                <el-tooltip content="Tải file .mlb đang show bên dưới" placement="top-start" effect="dark">
                  <el-button size="default" @click="handleDownloadCurrentVsPointRewardFile"><i class="el-icon-download"></i> Download current config</el-button>
                </el-tooltip>
              </el-col>
            </el-row>
          </MyFormItem>

          <el-collapse class="space-to-bottom" :accordion="false" v-if="data.VersusConfig.PointReward.length > 0">
            <el-collapse-item title="View Imported Data">
              <el-table :data="data.VersusConfig.PointReward" border>
                <el-table-column
                    prop="Index"
                    label="Index">
                </el-table-column>
                <el-table-column
                    prop="RangeMin"
                    label="RangeMin">
                </el-table-column>
                <el-table-column
                    prop="RangeMax"
                    label="RangeMax">
                </el-table-column>
                <el-table-column
                    prop="Win"
                    label="Win">
                </el-table-column>
                <el-table-column
                    prop="EnemyLose"
                    label="EnemyLose">
                </el-table-column>
                <el-table-column
                    prop="Draw"
                    label="Draw">
                </el-table-column>
                <el-table-column
                    prop="EnemyDraw"
                    label="EnemyDraw">
                </el-table-column>
                <el-table-column
                    prop="Lose"
                    label="Lose">
                </el-table-column>
                <el-table-column
                    prop="EnemyWin"
                    label="EnemyWin">
                </el-table-column>
              </el-table>
            </el-collapse-item>
          </el-collapse>

          <MyFormItem label="AffectId config File" tooltip="Đẩy file AffectId config lên">
            <el-row :gutter="20" type="flex" justify="start">
              <el-col :offset="0">
                <el-upload
                    drag
                    action=""
                    :file-list="groupAffectIdFileList"
                    :auto-upload="false"
                    ref=""
                    :on-change="handleChangeVsAffectIdFileList"
                    :on-remove="handleChangeVsAffectIdFileList"
                    class="file-list-item">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                </el-upload>
              </el-col>
              <el-col  :offset="0">
                <el-tooltip content="Tải file .mlb đang show bên dưới" placement="top-start" effect="dark">
                  <el-button size="default" @click="handleDownloadCurrentVsAffectIdFile"><i class="el-icon-download"></i> Download current AffectId</el-button>
                </el-tooltip>
              </el-col>
            </el-row>
          </MyFormItem>

          <el-collapse class="space-to-bottom" :accordion="false" v-if="showVsAffectId.length > 0">
            <el-collapse-item title="View Imported Data">
              <el-table :data="showVsAffectId" border>
                <el-table-column
                    prop="name"
                    label="Name"
                    width="150"
                />
                <el-table-column
                    label="AffectId"
                >
                  <template #default="propsAffectGroup">
                    <el-table :data="propsAffectGroup.row.data" border>
                      <el-table-column
                          prop="Index"
                          label="Index"
                          width="100"/>
                      <el-table-column
                          label="ListId"
                      >
                        <template #default="propsAffectId">
                          <el-table :data="propsAffectId.row.ListId" border>
                            <el-table-column
                                prop="Id"
                                label="Id"/>
                          </el-table>
                        </template>
                      </el-table-column>
                    </el-table>
                  </template>
                </el-table-column>


              </el-table>
            </el-collapse-item>
          </el-collapse>
        </el-collapse-item>

        <el-collapse-item v-if="data.TypeLb === `Lbq`">
          <template #title>
            <h2>Disable Config</h2>
          </template>

          <el-tabs
              type="border-card"
              v-model="disableConfigData.currentSelectLoopSet">
            <el-tab-pane
              v-for="(item, index) in data.DisableConfig"
              :key="index"
              :label="index.toString()"
              :name="index.toString()"
            >
             <el-tabs
                 type="card"
                 v-model="disableConfigData.currentSelectedName">
               <el-tab-pane
                 v-for="(value, key) in item"
                 :key="key"
                 :label="key"
                 :name="key">
                   <MyFormItem label="Disable Config File" tooltip="">
                     <el-row :gutter="20" type="flex" justify="start">
                       <el-col :offset="0">
                         <el-upload
                             drag
                             action=""
                             :file-list="disableConfigFileList"
                             :auto-upload="false"
                             ref=""
                             :multiple="false"
                             :on-change="handleChangeDisableConfigFileList"
                             :on-remove="handleChangeDisableConfigFileList"
                             class="file-list-item">
                           <i class="el-icon-upload"></i>
                           <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                         </el-upload>
                       </el-col>
                       <el-col  :offset="0">
                         <el-tooltip content="Tải file .mlb đang show bên dưới" placement="top-start" effect="dark">
                           <el-button size="default" @click="handleDownloadDisableConfigFile"><i class="el-icon-download"></i> Download current config</el-button>
                         </el-tooltip>
                       </el-col>
                     </el-row>
                   </MyFormItem>
                   <el-table :data="value" border v-if="value.length > 0">
                     <el-table-column label="Index" width="100">
                       <template #default="scopeName">
                         {{ scopeName.$index }}
                       </template>
                     </el-table-column>
                     <el-table-column label="Name">
                       <template #default="scopeName">
                         {{ scopeName.row }}
                       </template>
                     </el-table-column>
                   </el-table>
               </el-tab-pane>
             </el-tabs>
            </el-tab-pane>

          </el-tabs>
<!--          <el-tabs-->
<!--              type="border-card"-->
<!--              v-model="disableConfigData.currentSelectedName">-->
<!--            <el-tab-pane-->
<!--              v-for="(value, key) in data.DisableConfig"-->
<!--              :key="key"-->
<!--              :label="key"-->
<!--              :name="key"-->
<!--              >-->
<!--              <MyFormItem label="Disable Config File" tooltip="Đẩy file Reward lên">-->
<!--                <el-row :gutter="20" type="flex" justify="start">-->
<!--                  <el-col :offset="0">-->
<!--                    <el-upload-->
<!--                        drag-->
<!--                        action=""-->
<!--                        :file-list="disableConfigFileList"-->
<!--                        :auto-upload="false"-->
<!--                        ref=""-->
<!--                        :multiple="false"-->
<!--                        :on-change="handleChangeDisableConfigFileList"-->
<!--                        :on-remove="handleChangeDisableConfigFileList"-->
<!--                        class="file-list-item">-->
<!--                      <i class="el-icon-upload"></i>-->
<!--                      <div class="el-upload__text">Drop file here or <em>click to upload</em></div>-->
<!--                    </el-upload>-->
<!--                  </el-col>-->
<!--                  <el-col  :offset="0">-->
<!--                    <el-tooltip content="Tải file .mlb đang show bên dưới" placement="top-start" effect="dark">-->
<!--                      <el-button size="default" @click="handleDownloadDisableConfigFile(key)"><i class="el-icon-download"></i> Download current config</el-button>-->
<!--                    </el-tooltip>-->
<!--                  </el-col>-->
<!--                </el-row>-->
<!--              </MyFormItem>-->

<!--              <el-table :data="value" border v-if="value.length > 0">-->
<!--                <el-table-column label="Index" width="100">-->
<!--                  <template #default="scopeName">-->
<!--                    {{ scopeName.$index }}-->
<!--                  </template>-->
<!--                </el-table-column>-->
<!--                <el-table-column label="Name">-->
<!--                  <template #default="scopeName">-->
<!--                    {{ scopeName.row }}-->
<!--                  </template>-->
<!--                </el-table-column>-->
<!--              </el-table>-->

<!--            </el-tab-pane>-->
<!--          </el-tabs>-->
        </el-collapse-item>
      </el-collapse>

      <!-- Reward Progress -->
<!--      <el-row v-if='data.StatusLb === "Rewarding"'>-->
<!--        <MyFormItem label="GroupRewarded" tooltip="">-->
<!--          <el-input class="form-input" v-model="data.RewardProcess.GroupRewarded" size="normal" disabled/>-->
<!--        </MyFormItem>-->
<!--        <MyFormItem label="Group Current Reward" tooltip="">-->
<!--          <el-input class="form-input" v-model="data.RewardProcess.GroupCurrentReward" size="normal" disabled/>-->
<!--        </MyFormItem>-->
<!--        <MyFormItem label="Current Lb Index" tooltip="">-->
<!--          <el-input class="form-input" v-model="data.RewardProcess.GroupRewardedLbIndex" size="normal" disabled/>-->
<!--        </MyFormItem>-->
<!--        <MyFormItem label="Current User Index" tooltip="">-->
<!--          <el-input class="form-input" v-model="data.RewardProcess.UserIndex" size="normal" disabled/>-->
<!--        </MyFormItem>-->
<!--      </el-row>-->

      <br/>

      <el-footer>
        <el-button type="primary" @click="applyData()" v-if="data.TypeLb !== `RbLittle`">
          Apply Data
        </el-button>

<!--        <el-button type="primary" @click="test()">-->
<!--          Test-->
<!--        </el-button>-->
      </el-footer>

    </el-form>
  </div>
</template>

<script>

import moment from "moment";
import MyFormItem from "@/views/loop-machine/component/myFormItem.vue"
import UploadTitle from "@/views/loop-machine/component/uploadTitle.vue"

import DownloadFile from '@/helpers/downloadFile'
import GenerateMLBFileContent from '@/helpers/generateMLBFileContent'
import ParseMLBfile from '@/helpers/parseMLBfile'
import ReadFile from '@/helpers/readFile'
import {Message} from "element-ui";
import request from "@/utils/request-service-base";
import store from "@/store";
import SpanTime from "@/views/loop-machine/component/spanTime.vue";
import TimeInput from "@/views/loop-machine/component/timeInput.vue";

function noop(typeLb) {}

export default {
  components:{
    TimeInput, SpanTime,
    MyFormItem,
    //UploadTitle
  },
  props: {
    data: {},
    onChange: {
      type: Function,
      default: noop
    },


  },
  data: () => {
    return {
      updateMsg: {
        msg: "",
      },
      needRefreshVersusConfig: false,
      rewardDataParam: {
        groupTabActive:"",
        indexTabActive:"",
      },

      personalRewardCachedData: {
        currentTabName: "",
      },

      rewardData: {
        selectedGroupName: [],
        currentSelectedIndex: 0,
      },

      clanRewardData: {
        selectedGroupName: [],
        currentSelectedIndex: 0,
      },

      disableConfigData: {
        currentSelectLoopSet: "0",
        currentSelectedName: "Avatar",
      },
    }
  },
  computed: {
    comingSoonTime: {
      get: function() {
        return this.data.Time.ComingSoon * 1000;
      },
      set: function (value) {
        this.data.Time.ComingSoon = value / 1000;
      }
    },
    startTime: {
      get: function() {
        return this.data.Time.Start * 1000;
      },
      set: function (value) {
        this.data.Time.Start = value / 1000;
      }
    },
    endTime: {
      get: function() {
        return this.data.Time.End * 1000;
      },
      set: function (value) {
        this.data.Time.End = value / 1000;
      }
    },
    lastModify: {
      get: function() {
        return this.data.LastModifyDate * 1000;
      },
      set: function (value) {
        this.data.LastModifyDate = value / 1000;
      }
    },
    nextTimeAffectRandom: {
      get: function() {
        if (this.data.TypeLb !== "Versus") {
          return 0;
        }

        return this.data.VersusConfig.GroupAffectSetting.GroupAffectTimeNewRandom * 1000;
      },
    },
    showCurrentAffect: {
      get: function() {
        if (this.data.TypeLb !== "Versus") {
          return "";
        }

        let ret = "";
        for (let i = 0; i < this.data.VersusConfig.GroupAffectSetting.GroupAffectRandomValue.length; i++) {
          ret += `${this.data.VersusConfig.GroupAffectSetting.GroupAffectRandomValue[i].CurrentId}, `;
        }

        return ret;
      }
    },

    rewardDataFileList: {
      get: function() {
        let index = this.rewardData.currentSelectedIndex;
        let ret = [];
        for (let i = 0; i < this.data.RewardData[index].length; i++) {
          ret.push(
              {
                name: `${this.data.RewardData[index][i].Name}`,
                rawText: this.generateMLBFileContentOfReward(this.data.RewardData[index][i].Gift),
              });
        }
        return ret;
      }
    },

    clanRewardDataFileList: {
      get: function() {
        let index = this.clanRewardData.currentSelectedIndex;
        let ret = [];
        for (let i = 0; i < this.data.ClanRewardData[index].length; i++) {
          ret.push(
              {
                name: `${this.data.ClanRewardData[index][i].Name}`,
                rawText: this.generateMLBFileContentOfClanReward(this.data.ClanRewardData[index][i].Gift),
              });
        }
        return ret;
      }
    },

    groupConfigFileList: {
      get: function() {
        let ret = [];

        if (this.data.GroupConfig.length > 0) {
          ret.push(
              {
                name: `GroupConfig.mlb`,
                rawText: this.generateMLBFileContentOfGroupConfig(this.data.GroupConfig),
              });
        }

        return ret;
      }
    },
    clanGroupConfigFileList: {
      get: function() {
        let ret = [];

        if (this.data.ClanGroupConfig.length > 0) {
          ret.push(
              {
                name: `ClanGroupConfig.mlb`,
                rawText: this.generateMLBFileContentOfClanGroupConfig(this.data.ClanGroupConfig),
              });
        }

        return ret;
      }
    },
    pointRewardFileList: {
      get: function () {
        // eslint-disable-next-line no-prototype-builtins
        if (!this.data.hasOwnProperty("VersusConfig")) {
          return [];
        }

        if (this.data.VersusConfig.PointReward.length <= 0) {
          return [];
        }

        return [
          {
            name: `VsPointReward.mlb`,
            rawText: this.generateMLBFileContentOfVsPointReward(this.data.VersusConfig.PointReward),
          }
        ];
      }
    },

    groupAffectIdFileList: {
      get: function () {
        // eslint-disable-next-line no-prototype-builtins
        if (!this.data.hasOwnProperty("VersusConfig")) {
          return [];
        }

        let ret = [];

        for (let i = 0; i < this.data.VersusConfig.GroupAffectSetting.GroupAffectId.length; i++) {
          ret.push(
              {
                name: `GroupAffectId_${i}`,
                rawText: this.generateMLBFileContentOfVsAffectId(this.data.VersusConfig.GroupAffectSetting.GroupAffectId[i]),
              });
        }

        return ret;
      }
    },

    avatarReplaceFileList: {
      get: function() {
        if (this.data.AvatarReplace.length === 0) {
          return [];
        }

        return [
          {
            name: "AvatarReplace",
            rawText: this.generateMLBFileContentOfAvatarReplace(this.data.AvatarReplace)
          }
        ];
      }
    },

    avatarFrameReplaceFileList: {
      get: function () {
        if (this.data.AvatarFrameReplace.length === 0) {
          return [];
        }

        return [
          {
            name: "AvatarFrameReplace",
            rawText: this.generateMLBFileContentOfAvatarFrameReplace(this.data.AvatarFrameReplace)
          }
        ];
      }
    },

    disableConfigFileList: {
      get: function() {
        if (this.data.TypeLb !== "Lbq") {
          return [];
        }

        let nameOfType = this.disableConfigData.currentSelectedName;
        let loopset = parseInt(this.disableConfigData.currentSelectLoopSet);
        let arrayData = this.data.DisableConfig[loopset][nameOfType];

        if (arrayData.length === 0) {
          return [];
        }

        return [
          {
            name: nameOfType,
            rawText: this.generateMLBFileContentOfDisableConfig(nameOfType, arrayData)
          }
        ];
      }
    },

    avatarFrameTableData: {
      get: function () {

        let ret = [];

        let title = [];
        let maxCount = 0;

        for (let slotIndex = 0; slotIndex < this.data.AvatarFrameReplace.length; slotIndex++) {
          title.push(`Slot_${slotIndex}`);
          maxCount = this.data.AvatarFrameReplace[slotIndex].length > maxCount ? this.data.AvatarFrameReplace[slotIndex].length : maxCount;
        }

        for (let i = 0; i < maxCount; i++) {
          let dataTemp = {
            Index: i + 1,
          };

          for (let j = 0; j < title.length; j++) {
            dataTemp[title[j]] = this.data.AvatarFrameReplace[j][i];
          }

          ret.push(dataTemp);
        }

        return ret;
      }
    },

    showVsAffectId: {
      get: function () {
        // eslint-disable-next-line no-prototype-builtins
        if (!this.data.hasOwnProperty("VersusConfig")) {
          return [];
        }

        let ret = [];

        for (let i = 0; i < this.data.VersusConfig.GroupAffectSetting.GroupAffectId.length; i++) {
          let dataAdd = [];
          for (let j = 0; j < this.data.VersusConfig.GroupAffectSetting.GroupAffectId[i].length; j++) {
            let listId = [];
            for (let c = 0; c < this.data.VersusConfig.GroupAffectSetting.GroupAffectId[i][j].length; c++) {
              listId.push({
                Id: this.data.VersusConfig.GroupAffectSetting.GroupAffectId[i][j][c]
              })
            }
            dataAdd.push({
              Index: j,
              ListId: listId,
            });
          }

          ret.push(
              {
                name: `GroupAffectId_${i}`,
                data: dataAdd,
              }
          );
        }

        return ret;
      }
    },

    bossWaitingTime() {
      return this.data.BossConfig.ActiveTime - this.data.BossConfig.LivingTime;
    },
  },
  async beforeMount() {
    this.applyRewardGroupName();
    this.applyClanRewardGroupName();
  },
  methods: {
    async changeActive(isActive) {
      try {
        this.$root.$emit("event-change-loading", true);
        await request({
          url: `/maintain/leaderboard/personal/change-leaderboard-active`,
          method: `post`,
          data: {
            TypeLb: this.data.TypeLb,
            IsActive: isActive,
            Modifier: store.getters.email
          }
        });

        Message({
          message: "Apply data success",
          type: "success",
          duration: 5 * 1000,
        });

        this.$root.$emit("event-change-loading", false);

        this.data.IsActive = isActive;
        this.onChange(this.data.TypeLb);
      }catch (ex) {
        Message({
          message: `Change Active error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },
    async applyData() {
      try {
        this.$root.$emit("event-change-loading", true);
        let dataRequest = this.data;
        dataRequest.Modifier = store.getters.email;
        if (this.data.TypeLb === "Versus") {
          dataRequest.NeedRefreshVersusConfig = this.needRefreshVersusConfig;
        }

        let response = await request({
          url: `/maintain/leaderboard/personal/update-leaderboard`,
          method: `post`,
          data: dataRequest
        });

        Message({
          message: "Apply data success",
          type: "success",
          duration: 5 * 1000,
        });

        this.updateMsg.msg = response.data.Message;

        this.$root.$emit("event-change-loading", false);
        this.$root.$emit("event-refresh-data", false);

      }catch (ex) {
        Message({
          message: `Apply data error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },

    async test(){
      try {
        this.$root.$emit("event-change-loading", true);
        let response = await request({
          url: `/leaderboard/personal/vs/get-group-affect`,
          method: `get`,
        });

        Message({
          message: "Apply data success",
          type: "success",
          duration: 5 * 1000,
        });

        this.$root.$emit("event-change-loading", false);

      }catch (ex) {
        Message({
          message: `Apply data error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },

    applyRewardGroupName() {
      this.rewardData.selectedGroupName = [];
      if (this.data.RewardData === undefined) {
        return;
      }
      for (let i = 0; i < this.data.RewardData.length; i++) {
        this.rewardData.selectedGroupName.push(this.data.RewardData[0][0].Name);
      }
    },

    applyClanRewardGroupName() {
      this.clanRewardData.selectedGroupName = [];
      if (this.data.ClanRewardData === undefined) {
        return;
      }
      for (let i = 0; i < this.data.ClanRewardData.length; i++) {
        this.clanRewardData.selectedGroupName.push(this.data.ClanRewardData[0][0].Name);
      }
    },

    //reward list
    generateMLBFileContentOfReward(listData) {
      let dataList = [];

      for (let j = 0; j < listData.length; j++) {
        let data = listData[j];
        let tempString = `${data.id}\t${data.title}\t${data.rankMin}\t${data.rankMax}\t${data.requireScore}\t${JSON.stringify(data.rewards)}`;
        dataList.push(tempString);
      }

      return GenerateMLBFileContent(`LeaderBoardGiftsConfig`,
          ['Index', 'Title', 'RankMin', 'RankMax', 'RequiredScore', 'Rewards'],
          ['int', 'string', 'int', 'int', 'int', 'string'],
          dataList);
    },

    async handleChangeRewardDataFileList(file, fileList) {
      this.$root.$emit("event-change-loading", true);

      let index = this.rewardData.currentSelectedIndex;

      this.data.RewardData[index] = [];

      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].raw) {
          fileList[i].rawText = await ReadFile(fileList[i].raw);
        }

        let rewardTemp = ParseMLBfile(fileList[i].rawText);
        let rewardsData = {
          Name: `${fileList[i].name.split('.mlb')[0]}`,
          Gift: [],
        };

        for (let j = 0; j < rewardTemp.length; j++) {
          let data = rewardTemp[j];
          rewardsData.Gift.push({
            id: parseInt(data.Index),
            title: data.Title,
            rankMin: parseInt(data.RankMin),
            rankMax: parseInt(data.RankMax),
            requireScore: parseInt(data.RequiredScore),
            rewards: JSON.parse(data.Rewards),
          })
        }

        this.data.RewardData[index].push(rewardsData);
      }

      this.$root.$emit("event-change-loading", false);
      this.applyRewardGroupName();
    },

    handleDownloadRewardFile(index) {
      for (let i = 0; i < this.data.RewardData[index].length; i++) {
          DownloadFile(`${this.data.RewardData[index][i].Name}.mlb`,
              this.generateMLBFileContentOfReward(this.data.RewardData[index][i].Gift));
      }
    },

    //clan reward list
    generateMLBFileContentOfClanReward(listData) {
      let dataList = [];

      for (let j = 0; j < listData.length; j++) {
        let data = listData[j];
        let tempString = `${data.id}\t${data.title}\t${data.rankMin}\t${data.rankMax}\t${data.requireScore}\t${JSON.stringify(data.rewards)}`;
        dataList.push(tempString);
      }

      return GenerateMLBFileContent(`LeaderBoardGiftsConfig`,
          ['Index', 'Title', 'RankMin', 'RankMax', 'RequiredScore', 'Rewards'],
          ['int', 'string', 'int', 'int', 'int', 'string'],
          dataList);
    },

    async handleChangeClanRewardFileList(file, fileList) {
      this.$root.$emit("event-change-loading", true);

      let index = this.clanRewardData.currentSelectedIndex;

      this.data.ClanRewardData[index] = [];

      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].raw) {
          fileList[i].rawText = await ReadFile(fileList[i].raw);
        }

        let rewardTemp = ParseMLBfile(fileList[i].rawText);
        let rewardsData = {
          Name: `${fileList[i].name.split('.mlb')[0]}`,
          Gift: [],
        };

        for (let j = 0; j < rewardTemp.length; j++) {
          let data = rewardTemp[j];
          rewardsData.Gift.push({
            id: parseInt(data.Index),
            title: data.Title,
            rankMin: parseInt(data.RankMin),
            rankMax: parseInt(data.RankMax),
            requireScore: parseInt(data.RequiredScore),
            rewards: JSON.parse(data.Rewards),
          })
        }

        this.data.ClanRewardData[index].push(rewardsData);
      }

      this.$root.$emit("event-change-loading", false);
      this.applyClanRewardGroupName();
    },

    handleDownloadClanRewardFile(index) {
      for (let i = 0; i < this.data.ClanRewardData[index].length; i++) {
        DownloadFile(`${this.data.ClanRewardData[index][i].Name}.mlb`,
            this.generateMLBFileContentOfClanReward(this.data.ClanRewardData[index][i].Gift));
      }
    },

    //groupConfig list
    generateMLBFileContentOfGroupConfig(listData) {
      let dataList = [];

      for (let j = 0; j < listData.length; j++) {
        let data = listData[j];
        let tempString = `${data.TypeUser}\t${data.Country}\t${data.Region.Min}\t${data.Region.Max}\t${data.TotalLeaderBoard}\t${data.MaxUserInLeaderBoard}\t${data.MaxPayUser}\t${data.MaxFreeUser1}\t${data.RewardName}`;
        dataList.push(tempString);
      }

      return GenerateMLBFileContent(`GroupConfig`,
          ['TypeUser', 'Country', 'Region_Min', 'Region_Max', 'TotalLeaderBoard', 'MaxUserInLeaderBoard', 'MaxPayUser', 'MaxFreeUser1', "RewardName"],
          ['string', 'string', 'int', 'int', 'int', 'int', 'int', 'int', 'string'],
          dataList);
    },

    async handleChangeGroupConfigFileList(file, fileList) {
      this.data.GroupConfig = [];

      if (fileList.length !== 1) {
        return;
      }

      this.$root.$emit("event-change-loading", true);

      if (fileList[0].raw) {
        fileList[0].rawText = await ReadFile(fileList[0].raw);
      }

      let rewardTemp = ParseMLBfile(fileList[0].rawText);
      for (let j = 0; j < rewardTemp.length; j++) {
        let data = rewardTemp[j];
        this.data.GroupConfig.push({
          TypeUser: data.TypeUser,
          Country: data.Country,
          Region: {
            Min: data.Region_Min,
            Max: data.Region_Max,
          },
          TotalLeaderBoard: data.TotalLeaderBoard,
          MaxUserInLeaderBoard: data.MaxUserInLeaderBoard,
          MaxPayUser: data.MaxPayUser,
          MaxFreeUser1: data.MaxFreeUser1,
          RewardName: data.RewardName,
        });
      }

      this.$root.$emit("event-change-loading", false);
    },

    handleDownloadCurrentGroupConfigFile() {
      if (this.data.GroupConfig.length <= 0) {
        return;
      }

      DownloadFile('GroupConfig.mlb',
          this.generateMLBFileContentOfGroupConfig(this.data.GroupConfig));
    },

    //clanGroupConfig list
    generateMLBFileContentOfClanGroupConfig(listData) {
      let dataList = [];

      for (let j = 0; j < listData.length; j++) {
        let data = listData[j];
        let tempString = `${data.TypeClan}\t${data.Country}\t${data.TotalLeaderBoard}\t${data.MaxClanInLeaderBoard}\t${data.RewardName}`;
        dataList.push(tempString);
      }

      return GenerateMLBFileContent(`GroupConfig`,
          ['TypeClan', 'Country', 'TotalLeaderBoard', 'MaxClanInLeaderBoard', "RewardName"],
          ['string', 'string', 'int', 'int', 'string'],
          dataList);
    },

    async handleChangeClanGroupConfigFileList(file, fileList) {
      this.data.ClanGroupConfig = [];

      if (fileList.length !== 1) {
        return;
      }

      this.$root.$emit("event-change-loading", true);

      if (fileList[0].raw) {
        fileList[0].rawText = await ReadFile(fileList[0].raw);
      }

      let rewardTemp = ParseMLBfile(fileList[0].rawText);
      for (let j = 0; j < rewardTemp.length; j++) {
        let data = rewardTemp[j];
        this.data.ClanGroupConfig.push({
          TypeClan: data.TypeClan,
          Country: data.Country,
          TotalLeaderBoard: data.TotalLeaderBoard,
          MaxClanInLeaderBoard: data.MaxClanInLeaderBoard,
          RewardName: data.RewardName,
        });
      }

      this.$root.$emit("event-change-loading", false);
    },

    handleDownloadCurrentClanGroupConfigFile() {
      if (this.data.ClanGroupConfig.length <= 0) {
        return;
      }

      DownloadFile('ClanGroupConfig.mlb',
          this.generateMLBFileContentOfClanGroupConfig(this.data.ClanGroupConfig));
    },

    //avatar replace list
    generateMLBFileContentOfAvatarReplace(listData) {
      let dataList = [];

      for (let j = 0; j < listData.length; j++) {
        let tempString = `${j}\t${listData[j]}`;
        dataList.push(tempString);
      }

      return GenerateMLBFileContent(`AvatarReplace`,
          ['Index', 'AvatarId'],
          ['int', 'int'],
          dataList);
    },

    async handleChangeAvatarReplaceFileList(file, fileList) {
      this.data.AvatarReplace = [];

      this.$root.$emit("event-change-loading", true);

      for (let i = 0; i < fileList.length; i++) {
        if (i >= 1) {
          break;
        }
        if (fileList[i].raw) {
          fileList[i].rawText = await ReadFile(fileList[i].raw);
        }

        let avatarReplaceList = ParseMLBfile(fileList[i].rawText);
        for (let j = 0; j < avatarReplaceList.length; j++) {
          this.data.AvatarReplace.push(avatarReplaceList[j].AvatarId);
        }
      }

      this.$root.$emit("event-change-loading", false);
    },

    handleDownloadCurrentAvatarReplace() {
      DownloadFile(`AvatarReplace.mlb`,
          this.generateMLBFileContentOfAvatarReplace(this.data.AvatarReplace));
    },

    //avatar frame replace list
    generateMLBFileContentOfAvatarFrameReplace(listData) {
      let columnTitle = ["Index"];
      let dataType = ["int"];

      let dataList = [];
      let maxCount = 0;

      for (let slotIndex = 0; slotIndex < listData.length; slotIndex++ ) {
        columnTitle.push(`Slot_${slotIndex}`);
        dataType.push("int");
        maxCount = listData[slotIndex].length > maxCount ? listData[slotIndex].length : maxCount;
      }

      for (let i = 0; i < maxCount; i++) {
        let tempString = `${i + 1}`;
        for (let slotIndex = 0; slotIndex < listData.length; slotIndex++ ) {
          if (i < listData[slotIndex].length) {
            tempString = `${tempString}\t${listData[slotIndex][i]}`;
          }
          else {
           tempString = `${tempString}\t`;
          }
        }

        dataList.push(tempString);
      }

      return GenerateMLBFileContent(`AvatarFrameReplace`,
          columnTitle,
          dataType,
          dataList);
    },

    async handleChangeAvatarFrameReplaceFileList(file, fileList) {
      this.data.AvatarFrameReplace = [];

      this.$root.$emit("event-change-loading", true);

      for (let i = 0; i < fileList.length; i++) {
        if (i >= 1) {
          break;
        }

        if (fileList[i].raw) {
          fileList[i].rawText = await ReadFile(fileList[i].raw);
        }

        let avatarFrameReplaceList = ParseMLBfile(fileList[i].rawText, true);
        let lines =  fileList[i].rawText.trim().split('\n');
        let headers = lines[1].split('\t');

        let slotCount = headers.length - 1;

        for (let slotIndex = 0; slotIndex < slotCount; slotIndex++) {
          let tempData = [];
          for (let j = 0; j < avatarFrameReplaceList.length; j++) {
            let property = `Slot_${slotIndex}`;
            tempData.push(avatarFrameReplaceList[j][property]);
          }

          this.data.AvatarFrameReplace.push(tempData);
        }
      }

      this.$root.$emit("event-change-loading", false);
    },

    handleDownloadCurrentAvatarFrameReplace() {
      DownloadFile(`AvatarFrameReplace.mlb`,
          this.generateMLBFileContentOfAvatarFrameReplace(this.data.AvatarFrameReplace));
    },

    //Vs point reward
    generateMLBFileContentOfVsPointReward(listData) {
      let dataList = [];

      for (let j = 0; j < listData.length; j++) {
        let data = listData[j];
        let tempString = `${data.Index}\t${data.RangeMin}\t${data.RangeMax}\t${data.Win}\t${data.EnemyLose}\t${data.Draw}\t${data.EnemyDraw}\t${data.Lose}\t${data.EnemyWin}`;
        dataList.push(tempString);
      }

      return GenerateMLBFileContent(`VsPointReward.mlb`,
          ['Index', 'RangeMin', 'RangeMax', 'Win', 'EnemyLose', 'Draw', 'EnemyDraw', 'Lose', "EnemyWin"],
          ['int', 'int', 'int', 'int', 'int', 'int', 'int', 'int', 'int'],
          dataList);
    },

    async handleChangeVsPointRewardFileList(file, fileList) {
      this.data.VersusConfig.PointReward = [];

      if (fileList.length !== 1) {
        return;
      }

      this.$root.$emit("event-change-loading", true);

      if (fileList[0].raw) {
        fileList[0].rawText = await ReadFile(fileList[0].raw);
      }

      let jsonArray = ParseMLBfile(fileList[0].rawText, true);
      for (let j = 0; j < jsonArray.length; j++) {
        let data = jsonArray[j];
        this.data.VersusConfig.PointReward.push({
          Index: data.Index,
          RangeMin: data.RangeMin,
          RangeMax: data.RangeMax,
          Win: data.Win,
          EnemyLose: data.EnemyLose,
          Draw: data.Draw,
          EnemyDraw: data.EnemyDraw,
          Lose: data.Lose,
          EnemyWin: data.EnemyWin,
        });
      }

      this.$root.$emit("event-change-loading", false);
    },

    handleDownloadCurrentVsPointRewardFile() {
      if (this.data.VersusConfig.PointReward.length <= 0) {
        return;
      }

      DownloadFile('VsPointReward.mlb',
          this.generateMLBFileContentOfVsPointReward(this.data.VersusConfig.PointReward));
    },

    //vs affect id
    generateMLBFileContentOfVsAffectId(listData) {
      let dataList = [];

      for (let j = 0; j < listData.length; j++) {
        let data = listData[j];
        let listId = "";
        for (let c = 0; c < data.length; c++) {
          listId += `${data[c]},`;
        }
        let tempString = `${j}\t${listId}`;
        dataList.push(tempString);
      }

      return GenerateMLBFileContent(`AffectId`,
          ['Index', 'ListId'],
          ['int', 'string'],
          dataList);
    },

    async handleChangeVsAffectIdFileList(file, fileList) {
      this.data.VersusConfig.GroupAffectSetting.GroupAffectId = [];

      this.$root.$emit("event-change-loading", true);

      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].raw) {
          fileList[i].rawText = await ReadFile(fileList[i].raw);
        }

        let dataTemp = ParseMLBfile(fileList[i].rawText);
        let fileData = [];

        for (let j = 0; j < dataTemp.length; j++) {
          let rowData = [];
          let splitData = dataTemp[j].ListId.split(',');
          for (let c = 0; c < splitData.length; c++) {
            if (splitData[c] === undefined || splitData[c] === null || splitData[c] === "") {
              continue;
            }
            rowData.push(splitData[c]);
          }

          fileData.push(rowData);
        }

        this.data.VersusConfig.GroupAffectSetting.GroupAffectId.push(fileData);
      }

      this.$root.$emit("event-change-loading", false);
    },

    handleDownloadCurrentVsAffectIdFile() {
      for (let i = 0; i < this.data.VersusConfig.GroupAffectSetting.GroupAffectId.length; i++) {
        DownloadFile(`GroupAffectId_${i}.mlb`,
            this.generateMLBFileContentOfVsAffectId(this.data.VersusConfig.GroupAffectSetting.GroupAffectId[i]));
      }
    },

    //disable Config
    generateMLBFileContentOfDisableConfig(fileName, listData) {
      let dataList = [];
      for (let j = 0; j < listData.length; j++) {
        let tempString = `${j}\t${listData[j]}`;
        dataList.push(tempString);
      }

      return GenerateMLBFileContent(fileName,
          ['Index', 'Name'],
          ['int', 'string'],
          dataList);
    },

    async handleChangeDisableConfigFileList(file, fileList) {
      this.$root.$emit("event-change-loading", true);

      let nameOfType = this.disableConfigData.currentSelectedName;
      let loopset = parseInt(this.disableConfigData.currentSelectLoopSet);

      this.data.DisableConfig[loopset][nameOfType] = [];

      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].raw) {
          fileList[i].rawText = await ReadFile(fileList[i].raw);
        }

        let mlbData = ParseMLBfile(fileList[i].rawText);

        for (let j = 0; j < mlbData.length; j++) {
          this.data.DisableConfig[loopset][nameOfType].push(mlbData[j].Name);
        }
      }

      this.$root.$emit("event-change-loading", false);
    },

    handleDownloadDisableConfigFile() {
      let nameOfType = this.disableConfigData.currentSelectedName;
      let loopSet = parseInt(this.disableConfigData.currentSelectLoopSet);
      DownloadFile(`${nameOfType}.mlb`,
          this.generateMLBFileContentOfDisableConfig(nameOfType, this.data.DisableConfig[loopSet][nameOfType]));
    },

    handleOnBossActiveTimeChange(value){
      this.data.BossConfig.ActiveTime = value;
    },
    handleOnBossLivingTimeChange(value){
      this.data.BossConfig.LivingTime = value;
    },

    onSwitchTabRewardData(activeName, oldActiveName)  {
      //console.log(`onSwitchTabReward: ${oldActiveName}->${activeName}`);
      this.rewardData.currentSelectedIndex = parseInt(activeName);
    },

    onSwitchTabClanRewardData(activeName, oldActiveName)  {
      //console.log(`onSwitchTabReward: ${oldActiveName}->${activeName}`);
      this.clanRewardData.currentSelectedIndex = parseInt(activeName);
    },
  }
};
</script>